<template>
  <div class="DeviceConfig">
    <h2>设备管理</h2>
    <el-button type="primary" @click="handleAdd" :disabled="onCheckBtnAuthority('DeviceConfigAdd')">添加</el-button>
    <div class="searchWrap">
      <el-row>
        <el-col :span="5">
          <div class="sb">
            <span>设备编号</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="deviceCode"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>设备名称</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="deviceName"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="sb">
            <span>出入类型</span>
            <el-select
              v-model="inOuts"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option label="进门" :value="0" />
              <el-option label="出门" :value="1" />
            </el-select>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="sb">
            <span>是否纳入考勤</span>
            <el-select
              v-model="isAttendance"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option label="纳入考勤" :value="0" />
              <el-option label="不纳入考勤" :value="1" />
            </el-select>
          </div>
        </el-col>
      
        <el-col :span="5">
          <el-button type="primary" size="small" @click="onGetListData"
            >搜索</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="onlineStatus" label="在线状态">
        <template slot-scope="scope">
            <span class="red" v-if="scope.row.onlineStatus == 0">离线</span>
            <span class="green" v-if="scope.row.onlineStatus == 1">在线</span>
        </template>
      </el-table-column>
      <el-table-column prop="deviceCode" label="设备编号"> </el-table-column>
      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column prop="workerRegionName" label="区域"> </el-table-column>
      <el-table-column prop="locations" label="地点"> </el-table-column>
      <el-table-column prop="inOuts" label="出入类型">
        <template slot-scope="scope">
            <span v-if="scope.row.inOuts == 0">进门</span>
            <span v-if="scope.row.inOuts == 1">出门</span>
        </template>
      </el-table-column>
      <el-table-column prop="isAttendance" label="是否纳入考勤">
        <template slot-scope="scope">
            <span v-if="scope.row.isAttendance == 0">纳入考勤</span>
            <span v-if="scope.row.isAttendance == 1">不纳入考勤</span>
        </template>
      </el-table-column>

      <el-table-column prop="personnelRatio" label="已下发/总人数">
        <template slot-scope="scope">

          <el-button
            type="text"
            size="mini"
            @click="handleLogList(scope.row)"
            >{{scope.row.personnelRatio}}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="right" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" :disabled="onCheckBtnAuthority('DeviceConfigEdit')"
            >编辑</el-button
            >
          <el-button
            size="mini"
            @click="handleChangeDevice(scope.row)" :disabled="onCheckBtnAuthority('DeviceConfigChange')"
            >更换新设备</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="更换新设备"
      :visible.sync="showChangeDevice"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="formData" :rules="formRules" ref="ruleForm2" label-width="80px">
        <el-form-item label="设备编号" prop="deviceCode">
          <el-input clearable v-model="formData.deviceCode"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
    
    <el-dialog
      title="设备信息"
      :visible.sync="showFormData"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="formData" :rules="formRules" ref="ruleForm" label-width="80px">
        <el-form-item label="设备编号" prop="deviceCode" v-if="formType == 'add'">
          <el-input clearable v-model="formData.deviceCode"></el-input>
        </el-form-item>
        <el-form-item label="区域" prop="workerRegionId">
          <el-select
              v-model="formData.workerRegionId"
              clearable
              class="searchInput"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option v-for="item in areaList" :label="item.workerRegionName" :value="item.id" :key="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-input clearable v-model="formData.deviceName"></el-input>
        </el-form-item>
        <el-form-item label="地点" prop="">
          <el-input clearable v-model="formData.locations"></el-input>
        </el-form-item>
        <el-form-item label="出入类型" prop="inOuts">
          <el-radio-group v-model="formData.inOuts">
            <el-radio :label="0">进门</el-radio>
            <el-radio :label="1">出门</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否纳入考勤" prop="isAttendance">
          <el-radio-group v-model="formData.isAttendance">
            <el-radio :label="0">纳入考勤</el-radio>
            <el-radio :label="1">不纳入考勤</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input clearable v-model="formData.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog
      title="设备日志"
      :visible.sync="showLogList"
      width="40%"
      :before-close="handleClose"
    >
      <span style="margin-right: 20px;">处理结果</span>
      <el-select
        v-model="logStatus"
        class="searchInput"
        size="small"
        placeholder="请选择"
      >
        <el-option label="成功" :value="0" />
        <el-option label="失败" :value="1" />
        <el-option label="处理中" :value="2" />
      </el-select>
      <el-table :data="logList" style="margin-top: 20px">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="workerName" label="姓名"> </el-table-column>
        <el-table-column prop="cellPhone" label="电话"> </el-table-column>
        <el-table-column prop="status" label="处理结果">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">成功</span>
            <span v-if="scope.row.status == 1">失败</span>
            <span v-if="scope.row.status == 2">处理中</span>
          </template>
        </el-table-column>
        <el-table-column prop="failureContent" label="失败原因"> </el-table-column>
      </el-table>
      <el-pagination
        layout="total, prev, pager, next"
        :total="logListTotals"
        :page-size="logListPageSize"
        :current-page.sync="logListPageNum"
        @current-change="onGetLogList"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.DeviceConfig {
  padding: 20px;
  .red {
    color: #f00;
  }
  .green {
    color: #01b91c;
  }
}
</style>
    
<script>
export default {
  name: "DeviceConfig",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      deviceCode: "",
      deviceName: "",
      inOuts: "",
      isAttendance: "",
      areaList: [],
      formData: {
        id: null,
        deviceCode: null,
        projectId: null,
        workerRegionId: null,
        deviceName: null,
        locations: null,
        inOuts: null,
        isAttendance: null,
        remark: null,
        whetherOrNot: null
      },
      formRules: {
        deviceCode: [{ required: true, message: "必填项", trigger: "blur" }],
        workerRegionId: [{ required: true, message: "必填项", trigger: "blur" }],
        inOuts: [{ required: true, message: "必填项", trigger: "blur" }],
        isAttendance: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      formType: null,
      showFormData: false,
      showChangeDevice: false,
      showLogList:false,
      logId: null,
      logStatus: 0,
      logList: [],
      logListPageSize: 10,
      logListPageNum: 1,
      logListTotals: 0,
      
    };
  },
  computed: {},
  watch: {
    inOuts: function (val) {
      this.pageNum = 1
      this.onGetListData()
    },
    isAttendance: function (val) {
      this.pageNum = 1
      this.onGetListData()
    },
    logStatus: function () {
      this.logListPageNum = 1
      this.onGetLogList()
    }
  },
  mounted() {
    this.onGetListData();
    this.onGetAreaList()
    this.onGetPageBtn();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1688364598825689089",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        deviceCode: this.deviceCode,
        deviceName: this.deviceName,
        inOuts: this.inOuts,
        isAttendance: this.isAttendance,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/faceDevice/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取区域列表数据
    onGetAreaList: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        pageSize: 0,
        pageNum: 1,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/rnWorkerRegion/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.areaList = res.data.dataList;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 关闭
    handleClose: function () {
      this.formType = ''
      this.formData.id = ''
      this.formData.deviceCode = ''
      this.formData.projectId = ''
      this.formData.workerRegionId = ''
      this.formData.deviceName = ''
      this.formData.locations = ''
      this.formData.inOuts = ''
      this.formData.isAttendance = ''
      this.formData.remark = ''
      this.formData.whetherOrNot = ''

      this.showFormData = false
      this.showChangeDevice = false
      this.showLogList = false
      this.logId = null
      this.logList = []
      this.logListPageNum = 1
      this.logListTotals = 0
      this.$refs["ruleForm"].clearValidate();
      this.$refs["ruleForm2"].clearValidate();
    },
    // 添加
    handleAdd: function () {
      // this.handleClose()

      this.formType = 'add'

      this.formData.projectId = localStorage.getItem('projectId')
      this.formData.inOuts = 0
      this.formData.isAttendance = 0

      this.showFormData = true
    },
    // 编辑
    handleEdit: function (row) {
      // this.handleClose()
      this.formType = 'edit'

      this.formData.id = row.id
      this.formData.deviceCode = row.deviceCode
      this.formData.workerRegionId = row.workerRegionId
      this.formData.deviceName = row.deviceName
      this.formData.locations = row.locations
      this.formData.inOuts = row.inOuts
      this.formData.isAttendance = row.isAttendance
      this.formData.remark = row.remark

      this.showFormData = true
    },
    // 换新设备
    handleChangeDevice: function (row) {
      // this.handleClose()
      this.formType = 'change'

      this.formData.id = row.id
      this.formData.deviceCode = row.deviceCode
      this.formData.workerRegionId = row.workerRegionId
      this.formData.inOuts = row.inOuts
      this.formData.isAttendance = row.isAttendance
      this.formData.whetherOrNot = true

      this.showChangeDevice = true
    },
    // 确认提交数据
    handleSubmit: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmit();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 执行提交
    onSubmit: function () {
      var that = this;
      var data = this.formData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/faceDevice/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.handleClose();
            that.onGetListData();
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 设备日志
    handleLogList: function (row) {
      this.logId = row.id
      this.logListPageNum = 1
      this.onGetLogList()
    },
    // 获取设备日志
    onGetLogList: function () {
      var that = this;
      var data = {
        workerName: null,
        cellPhone: null,
        status: this.logStatus,
        deviceId: this.logId,
        projectId: localStorage.getItem('projectId'),
        pageSize: this.logListPageSize,
        pageNum: this.logListPageNum,
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/faceDevice/issueSuccesOrFail",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            console.log(res);
            that.logList = res.data.dataList
            that.logListPageSize = res.data.pageSize
            that.logListPageNum = res.data.pageNum
            that.logListTotals = res.data.totals
            that.showLogList = true
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
    